import { graphql, Link as GatsbyLink } from 'gatsby';

export const Link = ({
    text,
    href,
    target,
    to,
    title,
    external,
}: Queries.HygraphCMS_Link) => {
    if (to) {
        return (
            <GatsbyLink to={`/${to.slug}`} target={`_${target}`} title={title}>
                {text}
            </GatsbyLink>
        );
    }
    if (external && href) {
        return (
            <a href={href} target={`_${target}`} title={title} rel="nofollow">
                {text}
            </a>
        );
    } else if (href) {
        return (
            <GatsbyLink to={href} target={`_${target}`} title={title}>
                {text}
            </GatsbyLink>
        );
    }
    return null;
};

export const linkFragment = graphql`
    fragment HygraphCMS_Link on HygraphCMS_Link {
        id
        component
        text
        href
        target
        title
        external
        to {
            slug
        }
    }
`;
