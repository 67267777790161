import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const Video = (video: Queries.HygraphCMS_Video) => {
    const [modalShow, setModalShow] = useState(false);

    // TODO: Find out why this type casting is necessary
    const buttonTextColor = video.buttonTextColor;
    const buttonHexTextColor =
        (buttonTextColor?.hex as unknown as string) || '#000000';

    console.log(buttonTextColor);

    return (
        <div css={css({ position: 'relative' })}>
            <img
                onClick={() => setModalShow(true)}
                css={css({
                    maxWidth: '100%',
                    borderRadius: 'var(--bs-border-radius-lg)',
                    cursor: 'pointer',
                })}
                src={`https://img.youtube.com/vi/${video.youtubeId}/maxresdefault.jpg`}
                alt=""
            />
            <Button
                onClick={() => setModalShow(true)}
                css={css({
                    marginTop: '1rem',
                    position: 'absolute',
                    left: '1rem',
                    bottom: '1rem',
                    backgroundColor: buttonTextColor
                        ? 'transparent'
                        : '#00ff94',
                    ':hover': {
                        backgroundColor: buttonTextColor
                            ? 'transparent'
                            : '#26ffa4 !important',
                    },
                })}
                className="text-truncate text-light d-flex fw-bold text-uppercase"
                variant="link"
            >
                <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.333252 0L9.66658 6L0.333252 12V0Z"
                        fill={buttonHexTextColor}
                    />
                </svg>
                <small
                    css={css({
                        color: buttonHexTextColor,
                    })}
                >
                    {video.title}
                </small>
            </Button>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                css={css({
                    'div.modal-content': {
                        backgroundColor: 'black',
                        overflow: 'hidden',
                    },
                })}
            >
                <iframe
                    src={`https://www.youtube.com/embed/${video.youtubeId}?start=${video.youtubeStartSecond || 0}&amp;autoplay=true${video.youtubeParams || ''}`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    css={css({
                        width: '100%',
                        aspectRatio: '16 / 9',
                    })}
                />
            </Modal>
        </div>
    );
};

export const headingFragment = graphql`
    fragment HygraphCMS_Video on HygraphCMS_Video {
        id
        component
        title
        youtubeId
        youtubeStartSecond
        youtubeParams
        buttonTextColor {
            hex
        }
    }
`;
