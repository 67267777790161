import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export const Screenshot = ({
    screenshot: { screenshot, frame, screenshotType },
}: {
    screenshot: Queries.HygraphCMS_Screenshot;
}) => {
    if (!screenshot?.localFile?.childImageSharp) return null;
    if (!frame?.localFile?.childImageSharp) return null;

    const wrapperStyles = {
        tablet_right: css({
            position: 'relative',
            perspective: '6200px',
            perspectiveOrigin: '60% 160%',
            overflow: 'hidden',
            scale: '1.1',
            transformOrigin: 'top left',
        }),
        tablet_left: css({
            position: 'relative',
            perspective: '1848px',
            perspectiveOrigin: '20% 70%',
            overflow: 'hidden',
            scale: '1.1',
            transformOrigin: 'top right',
        }),
    };

    const frameStyles = {
        tablet_right: css({
            position: 'relative',
            zIndex: 10,
        }),
        tablet_left: css({
            position: 'relative',
            zIndex: 10,
        }),
    };

    const screenStyles = {
        tablet_right: css({
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
            right: 0,

            transform:
                'rotate3d(0, 1, 0.01, 36deg)  translate(24%, 10%) scale(1.4)',
            transformOrigin: 'top left',
        }),
        tablet_left: css({
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
            right: 0,

            transform:
                'rotate3d(0, 1, -0.01, 36deg)  translate(-28%, 16%) scale(1.4)',
            transformOrigin: 'top right',
        }),
    };

    const frameStyle = frameStyles[screenshotType];
    const screenStyle = screenStyles[screenshotType];

    return (
        <div css={wrapperStyles[screenshotType]}>
            <GatsbyImage
                css={frameStyle}
                image={frame?.localFile?.childImageSharp?.gatsbyImageData}
                alt=""
            />
            <GatsbyImage
                css={screenStyle}
                image={screenshot?.localFile?.childImageSharp?.gatsbyImageData}
                alt=""
            />
        </div>
    );
};

export const spacerFragment = graphql`
    fragment HygraphCMS_Screenshot on HygraphCMS_Screenshot {
        id
        component
        screenshotType

        screenshot {
            url
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 1024
                        layout: CONSTRAINED
                        placeholder: NONE
                    )
                }
            }
        }
        frame {
            url
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 1024
                        layout: CONSTRAINED
                        placeholder: NONE
                    )
                }
            }
        }
    }
`;
