import { css } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import {
    Badge,
    Button,
    Card,
    FormLabel,
    ToggleButton,
    ToggleButtonGroup,
} from 'react-bootstrap';
import { Paragraph } from './Paragraph';

type PricingModuleProps = {
    pricingModule: Queries.HygraphCMS_PricingModule;
    page: Queries.HygraphCMS_Page;
};

export const PricingModule = ({
    pricingModule: {
        priceLevels,
        remarksLabel,
        yearlyPaymentText,
        currency,
        monthlyPaymentText,
        yearlyPaymentDiscountText,
    },
    page,
}: PricingModuleProps) => {
    const [payAnnually, setPayAnnually] = useState(1);

    const formatter = new Intl.NumberFormat(
        page.locale === 'se' ? 'sv-SE' : 'en-US',
        {
            style: 'currency',
            currency,
            maximumSignificantDigits: 10,
        },
    );

    return (
        <div className="container">
            <div className="row mb-2">
                <div className="col d-flex justify-content-center align-items-center">
                    <ToggleButtonGroup
                        type="radio"
                        name="payAnnually"
                        value={payAnnually}
                        onChange={setPayAnnually}
                    >
                        <ToggleButton
                            id="tbg-btn-payAnnually-2"
                            variant="outline-dark"
                            value={0}
                        >
                            {monthlyPaymentText}
                        </ToggleButton>
                        <ToggleButton
                            id="tbg-btn-payAnnually-1"
                            variant="outline-dark"
                            value={1}
                        >
                            <span className="d-inline-block mr-2">
                                {yearlyPaymentText}
                            </span>
                            <Badge
                                pill
                                bg="primary"
                                className="ml-2 text-dark"
                                css={css({
                                    marginBottom: '-1rem;',
                                    marginLeft: '.5rem',
                                })}
                            >
                                {yearlyPaymentDiscountText}
                            </Badge>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className="row">
                {priceLevels.map((level, index) => (
                    <div
                        className="col-12 col-sm-6 col-md-12 col-lg-6 mb-2"
                        key={index}
                    >
                        <Card
                            style={{
                                // @ts-expect-error somthing wrong with type here
                                backgroundColor: level.cardColor.hex,
                                height: '100%',
                            }}
                        >
                            <Card.Body
                                css={css({
                                    padding: '1.5rem 1rem !important',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                })}
                            >
                                <div>
                                    <h5 className="text-nowrap font-weight-bold">
                                        <strong>{level.name}</strong>
                                    </h5>
                                    <div className="mt-3">
                                        <FormLabel className="mb-0 me-1">
                                            {remarksLabel}
                                        </FormLabel>
                                        {!!level.description && (
                                            <div
                                                css={css({
                                                    fontSize: '0.8rem',
                                                })}
                                            >
                                                <Paragraph
                                                    {...level.description}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="display-4 text-nowrap text-center d-block">
                                        {payAnnually
                                            ? formatter.format(level.price)
                                            : formatter.format(
                                                  level.pricePayedMonthly,
                                              )}
                                    </span>

                                    <Link
                                        to={`/${level.buttonLinkTo?.slug}`}
                                        className={`btn btn-primary text-uppercase`}
                                        css={css({
                                            width: '100%',
                                            margin: '12px 0 0',
                                        })}
                                    >
                                        {level.buttonText}
                                    </Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const PricingModuleFragment = graphql`
    fragment HygraphCMS_PriceLevel on HygraphCMS_PriceLevel {
        from
        to
        price
        name
        pricePayedMonthly
        contactUs
        buttonText
        buttonLinkTo {
            slug
        }
        cardColor {
            hex
        }
        description {
            ...HygraphCMS_Paragraph
        }
    }
    fragment HygraphCMS_PricingModule on HygraphCMS_PricingModule {
        stage
        locale
        id
        component
        currency
        defaultUsers
        usersLabel
        paymentLabel
        remarksLabel
        yearlyPaymentText
        monthlyPaymentText
        yearlyPaymentDiscountText
        priceLevels {
            ...HygraphCMS_PriceLevel
        }
    }
`;
