import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

type ImageProps = {
    image: Queries.HygraphCMS_Image;
    maxWidth?: string;
};

export const Image = ({ image, maxWidth = '100%' }: ImageProps) => {
    if (!image?.image?.localFile?.childImageSharp) return null;

    return (
        <div
            css={css({
                maxWidth,
                ...(image.inflate
                    ? {
                          scale: '1.2',
                          transformOrigin: image.inflateOrigin.length
                              ? image.inflateOrigin.join(' ')
                              : 'center',
                      }
                    : {}),
            })}
        >
            <GatsbyImage
                image={image?.image?.localFile?.childImageSharp.gatsbyImageData}
                alt={image?.altText}
            />
        </div>
    );
};

export const imgageFragment = graphql`
    fragment HygraphCMS_Image on HygraphCMS_Image {
        id
        component
        altText
        inflate
        inflateOrigin
        image {
            url
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 640
                        layout: CONSTRAINED
                        placeholder: NONE
                        quality: 100
                    )
                }
            }
        }
    }
    fragment HygraphCMS_Image_Small on HygraphCMS_Image {
        id
        component
        altText
        inflate
        image {
            url
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 120
                        layout: CONSTRAINED
                        placeholder: NONE
                    )
                }
            }
        }
    }
`;
