import { slugify } from 'utils/slugify';

type HeadingProps = {
    as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    children: unknown;
};

export function ParagraphHeading({ as, children }: HeadingProps) {
    const Component = `${as}` as keyof JSX.IntrinsicElements;

    // @ts-expect-error - unknown type here
    let title = children?.props?.parent.children;

    if (typeof title === `object` && Array.isArray(title)) {
        // @ts-expect-error - unknown type here
        const parsedTitle = title.map((child: unknown) => child?.text);

        title = parsedTitle.join(``);
    }

    const slug = typeof title === 'string' ? slugify(title) : '';

    // @ts-expect-error - unknown type here
    return <Component id={slug}>{children}</Component>;
}
