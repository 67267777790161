import { graphql } from 'gatsby';
import React from 'react';

export const Heading = (heading: Queries.HygraphCMS_Heading) => {
    return (
        <heading.tag className={`${heading.style || heading.tag} d-block`}>
            {heading.text}
        </heading.tag>
    );
};

export const headingFragment = graphql`
    fragment HygraphCMS_Heading on HygraphCMS_Heading {
        id
        component
        text
        tag
        style
    }
`;
