import { graphql, Link } from 'gatsby';

export const ButtonLink = ({
    text,
    href,
    to,
    title,
    target,
    variant = 'primary',
    outline,
    external,
}: Queries.HygraphCMS_ButtonLink) => {
    const buttonVariant = `btn-${outline ? 'outline-' : ''}${variant}`;
    if (to) {
        return (
            <Link
                to={`/${to.slug}`}
                target={`_${target}`}
                title={title}
                className={`btn ${buttonVariant} text-uppercase`}
            >
                {text}
            </Link>
        );
    }
    if (external && href) {
        return (
            <a
                href={href}
                target={`_${target}`}
                title={title}
                className={`btn ${buttonVariant} text-uppercase`}
            >
                {text}
            </a>
        );
    }
    return null;
};

export const butttonLinkFragment = graphql`
    fragment HygraphCMS_ButtonLink on HygraphCMS_ButtonLink {
        id
        component
        text
        href
        target
        title
        variant
        outline
        external
        to {
            slug
        }
    }
`;
