import { css } from '@emotion/react';
import { graphql, Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

type SignupFormProps = {
    form: Queries.HygraphCMS_SignupForm;
    page: Queries.HygraphCMS_Page;
};

const termsLinkStyle = css({
    textDecoration: 'none',
    color: 'var(--bs-gray-600)',
});

const termsLabelStyle = css({
    textTransform: 'uppercase',
    fontSize: '.7rem',
    fontWeight: '500',
});

export const SignupForm = ({ form, page: { locale } }: SignupFormProps) => {
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [token, setToken] = useState('');

    const handleCaptchaSuccess = (token: string | null) => {
        token ? setToken(token) : null;
    };

    const handleOnSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formElement = e.currentTarget;

        if (!token || !formElement.checkValidity()) {
            setValidated(true);
            return;
        }

        const formData = new FormData(formElement);

        formData.append('recaptchaResponse', token);

        const data = Object.fromEntries(formData.entries());

        await fetch(form.serverEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...data,
                locale,
            }),
        })
            .then((res) => {
                if (res.ok && form.thankYouPage) {
                    navigate(`/${form.thankYouPage.slug}`);
                } else {
                    setError(res.statusText);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const acceptTermsLabel = {
        en: (
            <span css={termsLabelStyle}>
                I approve the Roughly Rights{' '}
                <Link target="_blank" to="/en/terms" css={termsLinkStyle}>
                    terms of use
                </Link>{' '}
                &{' '}
                <Link target="_blank" to="/en/privacy" css={termsLinkStyle}>
                    privacy policy
                </Link>
            </span>
        ),
        se: (
            <span css={termsLabelStyle}>
                Jag godkänner Roughly Rights{' '}
                <Link target="_blank" to="/se/terms" css={termsLinkStyle}>
                    användarvillkor
                </Link>{' '}
                &{' '}
                <Link target="_blank" to="/se/privacy" css={termsLinkStyle}>
                    integritetspolicy
                </Link>
            </span>
        ),
    }[locale];

    return (
        <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
            {form.fields.map((field) => (
                <Form.Group
                    key={field.id}
                    className="mb-3 position-relative"
                    controlId={field.identifier}
                >
                    {field.type === 'checkbox' ? (
                        <Form.Check type="checkbox" label={field.label} />
                    ) : (
                        <>
                            <Form.Label>{field.label}</Form.Label>
                            <Form.Control
                                type={field.type}
                                name={field.identifier}
                                placeholder={field.placeholder || ''}
                                required={field.required}
                                className="text-uppercase"
                            />
                        </>
                    )}
                    <Form.Control.Feedback tooltip type="invalid">
                        {form.invalidFieldText}
                    </Form.Control.Feedback>
                </Form.Group>
            ))}
            <Form.Group className="my-3" controlId="acceptTerms">
                <Form.Check
                    type="checkbox"
                    name="acceptTerms"
                    label={acceptTermsLabel}
                    required
                />
            </Form.Group>
            <Alert variant="danger" show={!!error}>
                {error}
            </Alert>
            <Form.Group className="d-grid my-3">
                <ReCAPTCHA
                    sitekey="6LfLF64kAAAAAPLy0bwuB-gFG1qL75cP7ArI6BVu"
                    onChange={handleCaptchaSuccess}
                />
            </Form.Group>
            <Form.Group className="d-grid">
                <Button
                    variant="primary"
                    type="submit"
                    className="d-block"
                    disabled={!token}
                >
                    {form.submitText}
                </Button>
            </Form.Group>
        </Form>
    );
};

export const sugnupFormFragment = graphql`
    fragment HygraphCMS_SignupForm on HygraphCMS_SignupForm {
        id
        title
        invalidFieldText
        submitText
        serverEndpoint
        thankYouPage {
            id
            slug
        }
        component
        fields {
            ... on HygraphCMS_FormField {
                id
                type
                label
                identifier
                required
            }
        }
    }
`;
