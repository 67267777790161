import { graphql, Link } from 'gatsby';

const langMap = {
    en: '🌐 International',
    se: '🇸🇪 Svenska',
};

export const LanguagePicker = ({ page }: { page: Queries.HygraphCMS_Page }) => (
    <>
        {page.pageLocales
            ?.filter((pageLocale) => pageLocale?.locale !== page.locale)
            .map((pageLocale) => {
                if (pageLocale?.locale) {
                    return (
                        <Link
                            key={pageLocale.slug}
                            to={`/${pageLocale.slug}`}
                            className="d-block"
                        >
                            {langMap[pageLocale.locale]}
                        </Link>
                    );
                }
                return null;
            })}
    </>
);

export const languagePickerFragment = graphql`
    fragment HygraphCMS_LanguagePicker on HygraphCMS_LanguagePicker {
        id
        component
    }
`;
