import { graphql } from 'gatsby';
import React from 'react';

const classMap = {
    xs: 'py-1',
    sm: 'py-2',
    md: 'py-3',
    lg: 'py-4',
    xl: 'py-5',
};
export const Spacer = ({ size }: Queries.HygraphCMS_Spacer) => {
    return (
        <div className={classMap[size]}>
            <div className={classMap[size]} />
        </div>
    );
};

export const spacerFragment = graphql`
    fragment HygraphCMS_Spacer on HygraphCMS_Spacer {
        id
        component
        size
    }
`;
