import React from 'react';
import {
    Heading,
    Paragraph,
    Image,
    ButtonLink,
    Link,
    Spacer,
} from '@/components/blocks/parts';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Card as BootCard } from 'react-bootstrap';
import { css } from '@emotion/react';

const cardStyle = {
    minHeight: '10rem',
};

const cardThemes = {
    white: {
        backgroundColor: 'white',
    },
    whitetobeige: {
        backgroundImage: 'var(--rr-gradient-white-beige)',
    },
    beige: {
        backgroundColor: 'var(--rr-color-beige)',
    },
    turquoise: {
        backgroundColor: 'var(--rr-color-turquoise)',
    },
    dark: {
        color: 'white',
        backgroundColor: 'var(--rr-color-dark)',
    },
    green: {
        color: 'white',
        backgroundColor: 'var(--rr-color-green)',
    },
};

const cardStyleLinked = {
    ...cardStyle,
    backgroundImage:
        'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjY3OTY5IDExSDE5LjMzNDYiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMTAgMS42NjY5OUwxOS4zMzMzIDExLjAwMDNMMTAgMjAuMzMzNyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.25rem 1.25rem',
    backgroundPosition: 'right 1rem bottom 1rem',
    '&:hover': {
        backgroundImage:
            'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjY3OTY5IDExSDE5LjMzNDYiIHN0cm9rZT0iIzAwZmY5NCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xMCAxLjY2Njk5TDE5LjMzMzMgMTEuMDAwM0wxMCAyMC4zMzM3IiBzdHJva2U9IiMwMGZmOTQiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K)',
    },
};

const cardBodyStyle = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

type CardProps = {
    card: Queries.HygraphCMS_Card;
    page: Queries.HygraphCMS_Page;
};

export const Card = ({ card: { cardContent, theme } }: CardProps) => {
    if (!cardContent?.cardRows.length) return null;

    const { cardTo, cardHref, cardExternal, cardTarget, cardRows } =
        cardContent;

    const card = (
        <BootCard
            css={{
                ...(theme ? cardThemes[theme] : {}),
                ...((cardHref && cardExternal) || cardTo
                    ? cardStyleLinked
                    : cardStyle),
            }}
        >
            <BootCard.Body css={cardBodyStyle}>
                {cardRows.map((row) => {
                    switch (row.component) {
                        case 'Heading':
                            const heading = row as Queries.HygraphCMS_Heading;
                            return <Heading key={heading.id} {...heading} />;
                        case 'Paragraph':
                            const paragraph =
                                row as Queries.HygraphCMS_Paragraph;
                            return (
                                <Paragraph key={paragraph.id} {...paragraph} />
                            );
                        case 'Image':
                            const image = row as Queries.HygraphCMS_Image;
                            return (
                                <Image
                                    key={image.id}
                                    image={image}
                                    maxWidth="50%"
                                />
                            );
                        case 'ButtonLink':
                            const buttonLink =
                                row as Queries.HygraphCMS_ButtonLink;
                            return (
                                <ButtonLink
                                    key={buttonLink.id}
                                    {...buttonLink}
                                />
                            );
                        case 'Link':
                            const link = row as Queries.HygraphCMS_Link;
                            return <Link key={link.id} {...link} />;
                        case 'Spacer':
                            const spacer = row as Queries.HygraphCMS_Spacer;
                            return <Spacer key={spacer.id} {...spacer} />;
                        default:
                            return null;
                    }
                })}
            </BootCard.Body>
        </BootCard>
    );

    if (cardTo) {
        return (
            <GatsbyLink target={`_${cardTarget}`} to={`/${cardTo.slug}`}>
                {card}
            </GatsbyLink>
        );
    }

    if (cardHref && cardExternal) {
        return (
            <a target={`_${cardTarget}`} href={cardHref}>
                {card}
            </a>
        );
    }

    return card;
};

export const cardFragment = graphql`
    fragment HygraphCMS_CardContent on HygraphCMS_CardContent {
        id
        cardHref
        cardExternal
        cardTarget
        cardTo {
            slug
        }
        cardRows {
            ...HygraphCMS_Heading
            ...HygraphCMS_Paragraph
            ...HygraphCMS_Link
            ...HygraphCMS_Spacer
            ...HygraphCMS_ButtonLink
            ...HygraphCMS_Image_Small
        }
    }
    fragment HygraphCMS_Card on HygraphCMS_Card {
        id
        component
        theme
        cardContent {
            ...HygraphCMS_CardContent
        }
    }
`;
